import * as React from 'react';
import { ComponentProps, ErrorInfo, FunctionComponent, ReactNode, Suspense, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { logException, useLoggingAgent } from '@autoguru/logs';
import { ErrorFallback } from '../ErrorFallback';
import { AsyncFallback } from '../AsyncFallback';
import { ENV_NAME } from '@autoguru/global-configs';

interface Props
	extends ComponentProps<typeof Suspense>,
		Pick<ComponentProps<typeof ErrorBoundary>, 'onError'> {
	errorFallback?: ReactNode;
}

const logError = (error: Error, info: ErrorInfo, agent: Parameters<typeof logException>[2]) => (
	logException((error as Parameters<typeof logException>[0]), {
		react: {
			componentStack: info?.componentStack,
		},
	}, agent));

const FallbackSpinner = (
	<AsyncFallback />
);

export const AsyncBoundary: FunctionComponent<Props> = ({
															children,
															fallback = FallbackSpinner,
															errorFallback: ErrorFallbackElement,
															onError: incomingOnError,
															...suspenseProps
														}) => {
	const agent = useLoggingAgent();
	const actionOnError = (error: Error, info: ErrorInfo) => {
		logError(error, info, agent);
		if (typeof incomingOnError === 'function') incomingOnError(error, info);
	};

	const fallbackRender = useCallback(({ error, resetErrorBoundary }) => (
		<ErrorFallback error={error}
					   hideDetails={ENV_NAME === 'prod'}
					   resetErrorBoundary={resetErrorBoundary} />
	), []);

	return (
		<ErrorBoundary onError={actionOnError}
					   fallbackRender={ErrorFallbackElement ? () => ErrorFallbackElement : fallbackRender}>
			<Suspense {...suspenseProps} fallback={fallback}>
				{children}
			</Suspense>
		</ErrorBoundary>
	)
};
