import { GraphQLFormattedError } from 'graphql';
import { ErrorWithGraphQLErrors } from '@autoguru/logs/exceptions/types';

const GRAPH_ERROR_MEEAGE_PREFIX = 'GraphQLError';

export class GraphError extends Error implements ErrorWithGraphQLErrors {
	constructor(
		message: string,
		public graphqlErrors: ReadonlyArray<GraphQLFormattedError>,
	) {
		super(message.startsWith(GRAPH_ERROR_MEEAGE_PREFIX) ? message : `${GRAPH_ERROR_MEEAGE_PREFIX}: ${message}`);
	}

	toValue() {
		return this.toJSON();
	}

	toJSON() {
		return JSON.stringify(this.graphqlErrors);
	}
}
