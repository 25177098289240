import * as React from 'react';
import { ExoticComponent, memo, ReactNode } from 'react';

import { BoxedMessageScreen } from '../BoxedMessageScreen';
import { CDN } from '@autoguru/global-configs';

interface Props {
	title?: string;
	children?: ReactNode;
	className?: string;
	boxed?: boolean;
	imgUrl?: string;
}

export const NoResults: ExoticComponent<Props> = memo(
	({ imgUrl = `${CDN}/images/illustrations/noresults.svg`, ...rest }) => (
		<BoxedMessageScreen {...rest} imgUrl={imgUrl} />
	),
);
