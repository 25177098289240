import type { Auth0Client } from '@auth0/auth0-spa-js';
import { invariant } from '@autoguru/utilities';
import * as React from 'react';
import {
	ComponentType,
	createContext,
	FunctionComponent,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useRef,
} from 'react';

import { login, logout } from '../lib/behaviour';
import { Button, Inline, Stack, Text } from '@autoguru/overdrive';
import { cleaClinetBrowserCache, hasAuthParams } from '../lib/utils';
import { ContainedLayout } from '../../layout';
import { NoResults } from '@autoguru/components';
import { AsyncBoundary } from '@autoguru/suspense';
import { BASE_URL } from '@autoguru/global-configs';

export interface AuthOptions {
	allow_socials: boolean;
	initialScreen?: 'login' | 'signUp';
	token?: string;
	returnTo?: string;
	logoUrl?: string;
	termsAndConditionsUrl?: string;
	privacyPolicyUrl?: string;
	autoguru_tenant?: string;
	autoguru_region?: string;
}

export interface AuthState {
	isAuthenticated: boolean;
	optionalAuthentication: boolean;
	returnTo?: string;
	options?: AuthOptions;
}

interface AuthContext {
	options: AuthOptions;

	read(): AuthState | undefined;
}

const context = createContext<AuthContext | null>(null);

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;
const handleCallBacks = (
	client: Auth0Client,
	incomingResolve?: Function,
	incomingReject?: Function,
	failedCount = 0,
): Promise<{
	appState: Awaited<
		ReturnType<(typeof client)['handleRedirectCallback']>
	>['appState'];
	isAuthed: boolean;
}> => {
	const redirectPromiseHandler = (resolve, reject) => {
		client
			.handleRedirectCallback()
			.then((state) => resolve(state))
			.catch(() => {
				failedCount++;
				if (failedCount > 20) reject('Authentication failed ');
				else
					setTimeout(async () => {
						const isAuthed = await client.isAuthenticated();
						if (isAuthed) return resolve({ isAuthed });
						// Try again in 300MS
						handleCallBacks(client, resolve, reject, failedCount);
					}, 500);
			});
	};

	let handleRedirectPromise;
	if (!incomingResolve)
		handleRedirectPromise = new Promise(redirectPromiseHandler);
	else redirectPromiseHandler(incomingResolve, incomingReject);

	return handleRedirectPromise;
};

const createAuthResource = (
	client: Auth0Client,
	optionalAuthentication: boolean,
): AuthContext['read'] => {
	let result: AuthState = null as unknown as AuthState;
	let error: Error = null as unknown as Error;

	const promiseChain = Promise.resolve()
		.then(async () => {
			if (hasAuthParams()) {
				const { isAuthed, appState } = await handleCallBacks(client);
				isAuthed
					? await client.checkSession()
					: window.history.replaceState(
							{},
							document.title,
							(appState as AuthState)?.returnTo ||
								window.location.toString(),
					  );
			}

			return client.checkSession();
		})
		.then(async () => {
			const isAuthenticated = await client.isAuthenticated();
			result = {
				optionalAuthentication,
				isAuthenticated,
			};
		})
		.catch((err) => {
			error = err;
		});

	return () => {
		if (result !== null) return result;
		if (error !== null) throw error;
		throw promiseChain;
	};
};

const authClientCache = new WeakMap<Auth0Client, AuthContext['read']>();

interface ProviderProps extends AuthOptions {
	client: Auth0Client;
	optionalAuthentication?: boolean;
	children: ReactNode;
	logoUrl?: string;
}

export const AuthProvider: FunctionComponent<ProviderProps> = ({
	children,
	client,
	optionalAuthentication = false,
	logoUrl,
	...incomingOptions
}) => {
	const defaultOptions: AuthOptions = useMemo(
		() => ({
			allow_socials: false,
			logoUrl,
			...incomingOptions,
		}),
		[incomingOptions],
	);
	const authTracker = useRef<AuthContext['read']>(
		authClientCache.get(client) as unknown as AuthContext['read'],
	);
	if (!authTracker.current)
		authClientCache.set(
			client,
			(authTracker.current = createAuthResource(
				client,
				optionalAuthentication,
			)),
		);

	return (
		<context.Provider
			value={{
				read: authTracker.current,
				options: defaultOptions,
			}}>
			{children}
		</context.Provider>
	);
};

/**
 * Returns some auth related fields, note this will suspend till we know about its value.
 */
export const useAuth = (): AuthState => {
	const ctx = useContext<AuthContext>(context);

	if (__DEV__)
		invariant(
			ctx !== null,
			"Please define the AuthProvider in this app's tree",
		);

	return { ...ctx.read(), options: ctx.options };
};

/**
 * If the current auth session holds true that the user isnt logged in, then we redirect them to the login flow.
 *
 * Note the returned component will suspend till we know if they're logged in or not.
 */

export const withRequiredAuthentication =
	<P extends unknown = {}>(
		Component: ComponentType<P>,
		showAuthErrorScreen = false,
		FallbackComponent?: ReactNode | null,
		FailedComponent?: ReactNode | null,
	): FunctionComponent<P> =>
	(props: P) =>
		(
			<AsyncBoundary
				fallback={null}
				onError={cleaClinetBrowserCache}
				errorFallback={
					showAuthErrorScreen ? (
						typeof FallbackComponent === 'undefined' ? (
							<ContainedLayout width="medium">
								<NoResults
									boxed={false}
									title="Authentication Failed">
									<Stack alignItems="center" space="3">
										<Stack space="none">
											<Text
												size="3"
												is="p"
												align="center">
												Something went wrong during your
												authentication
											</Text>
										</Stack>
										<Inline
											noWrap
											space="2"
											alignX="center">
											<Button
												size="small"
												variant="primary"
												onClick={() =>
													login(
														window.location.toString(),
													)
												}>
												Try Again
											</Button>
											<Button
												size="small"
												variant="secondary"
												onClick={() =>
													window.location.assign(
														BASE_URL,
													)
												}>
												Home
											</Button>
										</Inline>
									</Stack>
								</NoResults>
							</ContainedLayout>
						) : (
							FailedComponent
						)
					) : (
						<></>
					)
				}>
				<AuthenticatedComp
					{...(props as any)}
					Component={Component}
					FallbackComponent={FallbackComponent}
				/>
			</AsyncBoundary>
		);

interface Props {
	Component: ComponentType<any>;
	FallbackComponent?: ReactNode | null;
	FailedComponent?: ReactNode | null;
}

//@ts-ignore
const AuthenticatedComp: FunctionComponent<Props> = ({
	Component,
	FallbackComponent,
	...props
}) => {
	const { isAuthenticated, optionalAuthentication, options } =
		useAuth() as unknown as AuthState;

	useEffect(() => {
		if (!isAuthenticated && !optionalAuthentication) {
			login(options.returnTo || window.location.toString(), options);
		}
	}, [options.returnTo, isAuthenticated, optionalAuthentication]);
	useEffect(() => {
		if (__DEV__ && process.__browser__) {
			// @ts-ignore
			window['logout'] = () => void logout(window.location.toString());
			// @ts-ignore
			window['login'] = () =>
				void login(window.location.toString(), options);
		}
	}, []);
	return (
		isAuthenticated || optionalAuthentication ? (
			// @ts-ignore
			<Component {...props} />
		) : typeof FallbackComponent === 'undefined' ? (
			<ContainedLayout>
				<Text align="center">Redirecting...</Text>
			</ContainedLayout>
		) : (
			FallbackComponent
		)
	) as ReactNode;
};
