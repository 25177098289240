import { Box, Button, Icon, Tooltip } from '@autoguru/overdrive';
import * as React from 'react';
import { ComponentProps, FunctionComponent } from 'react';
import { EAlignment } from '@autoguru/overdrive/dist/components/Positioner/alignment';
import { ContentCopyIcon } from '@autoguru/icons';
import { useClipboardCopy } from '../hooks/useClipboardCopy';

export interface Props extends Omit<ComponentProps<typeof Button>, 'children'> {
	className?: string;
	title?: string;
	toolTip?: string;
	tooltipAlignment?: ComponentProps<typeof Tooltip>['alignment'];

	stringProvider(): string | Promise<string>;
}

export const CopyToClipboard: FunctionComponent<Props> = ({
	className,
	title = 'COPY',
	toolTip = 'Copy to clipboard',
	tooltipAlignment = EAlignment.LEFT,
	stringProvider,
															  minimal = true,
															  variant = 'secondary',
															  size = 'small',

	...buttonProps
}) => {
	const [onCopyRequest, tip] = useClipboardCopy({
		stringProvider,
	});

	return (
		<Tooltip
			closeAfter={3e3}
			alignment={tooltipAlignment}
			label={tip || toolTip}
		>
			<Box className={className}>
				<Button
					minimal={minimal}
					variant={variant}
					size={size}
					{...buttonProps}
					onClick={onCopyRequest}
				>
					{title}
					<Icon icon={ContentCopyIcon as any} />
				</Button>
			</Box>
		</Tooltip>
	);
};
