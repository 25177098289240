import { MicroAgent } from '@newrelic/browser-agent/loaders/micro-agent';
import { CreateLoggingAgentOptions, defaultOptions } from './agentConfigs';
import { augmentAgent } from './augmentAgent';

export const sharedAgentGlobalVariable = '__sharedLoggingAgent';
export const getLoggingAgent = () => window[sharedAgentGlobalVariable];
export const createLoggingAgent = ({agentIdentifier, options, }:{
	agentIdentifier:CreateLoggingAgentOptions[1];
	options?: CreateLoggingAgentOptions[0];
}) => {
	// If the agent already exists in the global variable, return it
	if (getLoggingAgent()) {
		return getLoggingAgent();
	}
	// Otherwise, create a new agent and store it in the global variable
	const agent = augmentAgent(new MicroAgent(options || defaultOptions, agentIdentifier));
	window[sharedAgentGlobalVariable] = agent;
	return agent;
};
