import { getFeatureName } from '@autoguru/feature-identification';
import { ENV_NAME, TENANT_NAME } from '@autoguru/global-configs';

export const buildLogContext = (contexts: any = {}) => {
	return {
		...contexts,
		featureName: getFeatureName(),
		mfeAppName: __GDU_APP_NAME__?.replace('@autoguru/', '') || 'NA',
		ag_env: ENV_NAME,
		ag_tenant: TENANT_NAME,
		screenWidth: process.__browser__?window.screen.width: 'NA',
		screenHeight: process.__browser__?window.screen.height: 'NA',
		screenAspectRatio: process.__browser__?window.screen.width / window.screen.height: 'NA',
	};
};
