import { LinearProgressIndicator } from '@autoguru/overdrive';
import * as React from 'react';

export const PageLoading = (
	<div
		style={{
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
		}}
	>
		<LinearProgressIndicator />
	</div>
);
