import { NEW_RELIC_ACCOUNT_ID, NEW_RELIC_APPLICATION_ID, NEW_RELIC_LICENSE_KEY } from '@autoguru/global-configs';
import type { MicroAgent } from '@newrelic/browser-agent/loaders/micro-agent';

export type CreateLoggingAgentOptions = ConstructorParameters<typeof MicroAgent>

const applicationID = NEW_RELIC_APPLICATION_ID;
const accountID = NEW_RELIC_ACCOUNT_ID;
const licenseKey = NEW_RELIC_LICENSE_KEY;
export const defaultOptions: CreateLoggingAgentOptions[0] = {
	init: {
		distributed_tracing: { enabled: true },
		privacy: { cookies_enabled: true },
		ajax: { deny_list: ['bam.eu01.nr-data.net'] },
	},
	info: {
		beacon: 'bam.eu01.nr-data.net',
		errorBeacon: 'bam.eu01.nr-data.net',
		licenseKey,
		applicationID,
		sa: 1,
	},
	loader_config: {
		accountID,
		trustKey: accountID,
		agentID: applicationID,
		licenseKey,
		applicationID,
	},
};
