import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import {
	AUTH_CLIENT_ID,
	AUTH_DOMAIN,
	AUTH_SCOPE,
} from '@autoguru/global-configs';

let clientCache: Auth0Client | null = null;
const getClientCache = (): Auth0Client | null =>
	process.__browser__ ? window['clientCache'] : clientCache;
const setClientCache = (client: Auth0Client) => {
	if (process.__browser__) window['clientCache'] = client;
	else clientCache = client;
};

interface Options {
	cacheLocation?: Auth0ClientOptions['cacheLocation'];
	audience?: string;
}

export const createClient = (options?: Options) => {
	const client: Auth0Client =
		getClientCache() ||
		new Auth0Client({
			clientId: AUTH_CLIENT_ID ?? '',
			domain: AUTH_DOMAIN ?? '',
			cacheLocation: options?.cacheLocation ?? 'localstorage',
			useRefreshTokens: true,
			useRefreshTokensFallback: true,
			authorizationParams: {
				audience:
					options?.audience ?? 'https://gateway.autoguru.com.au',
				redirect_uri: '',
				scope: AUTH_SCOPE ?? 'openid profile email',
			},
		});
	setClientCache(client);
	return client;
};

export const getCurrentClient = getClientCache;

export const hasCurrentClient = () => getClientCache() !== null;

export const isAuthenticated = () =>
	getCurrentClient()?.isAuthenticated() || false;

export const getAccessToken = () =>
	getCurrentClient()?.getTokenSilently() || null;
