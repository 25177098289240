import { Box, Heading, Stack } from '@autoguru/overdrive';
import * as React from 'react';
import { FunctionComponent, ReactNode } from 'react';
import * as styles from './BoxedMessageScreen.css';
import clsx from 'clsx';

interface Props {
	title?: string;
	children?: ReactNode;
	className?: string;
	boxed?: boolean;
	imgUrl?: string;
	illustration?: ReactNode;
}

export const BoxedMessageScreen: FunctionComponent<Props> =
	({
		 title,
		 children,
		 className = '',
		 imgUrl,
		 illustration,
		 boxed = true,
	 }) => (
			<Box
				aria-disabled="true"
				borderWidth={boxed ? '1' : 'none'}
				boxShadow={boxed ? '1' : 'none'}
				borderColour='light'
				paddingY={['2', '3']}
				paddingX={['2', '3']}
				borderRadius='1'
				className={clsx(styles.root, className)}
			>
				<Stack width='full' space="2" alignItems='center'>
					{imgUrl && (
						<img className={styles.successImage} src={imgUrl} />
					)}
					{illustration}
					<Stack width='full' space="5">
						{title ? <Heading is="h2">{title}</Heading> : null}
						{children}
					</Stack>
				</Stack>
			</Box>
		);
