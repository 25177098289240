import { FetchFunction, GraphQLResponseWithData, Observable, Variables } from 'relay-runtime';
import fetch from 'isomorphic-unfetch';
import { meros } from 'meros';
import { relayLogFn } from './environment';
import { AG_API_KEY, GRAPH_SERVICE_API } from '@autoguru/global-configs';
import { RequestParameters } from 'relay-runtime/lib/util/RelayConcreteNode';
import { createGraphError } from './utils';

interface FetchFactoryOptions {
	/**
	 * A method to _optionally_ return a JWT Bearer token to pass onto the network call. Return NULL to ignore.
	 */
	getToken?: () => Promise<string | null>;
	scheme?: string;
}

interface ExecutionPatchResult extends GraphQLResponseWithData {
	hasNext?: boolean;
}

type PostBody<T> = (
	| {
	query: string;
}
	| {}
	) & {
	id: string;
	variables: T;
};
type ParamsForCache = Extract<RequestParameters, { cacheID: string }>;
export const fetchQuery =
	({
		 getToken,
		 scheme = 'Bearer',
	 }: FetchFactoryOptions = {}): FetchFunction =>
		(params, variables, _cacheConfig, _uploadables) =>
			Observable.create((sink) => {
				const id = params.id || (params as ParamsForCache).cacheID;
				const postBody: PostBody<Variables> =
					params.id !== null
						? {
							id: params.id,
							variables,
						}
						: {
							id: (params as ParamsForCache).cacheID,
							query: params.text,
							variables,
						};

				const headers = {
					Accept: 'application/json, multipart/mixed',
					'Content-Type': 'application/json',
					'x-api-key': AG_API_KEY,
				};

				Promise.resolve()
					.then(async () => {
						let maybeToken = '';
						if ((maybeToken = await getToken?.()))
							headers['Authorization'] = `${scheme} ${maybeToken}`;

						const response = await fetch(
							`${GRAPH_SERVICE_API}/graphql`,
							{
								method: 'POST',
								cache: 'no-cache',
								headers,
								body: JSON.stringify(postBody),
							},
						)
							.then((r) => meros<ExecutionPatchResult>(r))
							.catch();

						if (isAsyncIterable(response)) {
							for await (const part of response) {
								if (!part.json)
									throw new Error(
										'Expected JSON from part, but received something else.',
									);

								const {
									data,
									path,
									hasNext,
									label,
									errors,
									extensions,
								} = part.body as unknown as any;
								if (Array.isArray(errors)) {
									throw createGraphError(errors);
								}
								sink.next({
									data,
									path,
									label,
									errors,
									extensions: {
										...extensions,
										is_final: !hasNext,
									},
								});
							}
						} else {
							const responseData =
								typeof response?.json === 'function'
									? await response.json()
									: null;

							if (
								!responseData ||
								Array.isArray(responseData?.errors)
							) {
								const error = createGraphError(responseData.errors);
								if (!responseData.data) throw error;
								else
									relayLogFn({
										name: 'network.error',
										error,
										networkRequestId: id as any,
									});
								if (responseData?.errors)
									sink.error(responseData.errors);
							}
							sink.next(responseData);
						}
						sink.complete();
					})
					.catch((error) => {
						sink.error(error);
					});
			});

const isAsyncIterable = (
	val: unknown,
): val is AsyncIterator<unknown> | AsyncIterableIterator<unknown> =>
	typeof val < 'u' &&
	val !== null &&
	((val as any)[Symbol.toStringTag] === 'AsyncGenerator' ||
		// @ts-ignore
		(Symbol.asyncIterator && Symbol.asyncIterator in val));
