const CODE_RE = /[?&]code=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

export const hasAuthParams = (searchParams = window.location.search): boolean =>
	CODE_RE.test(searchParams) || ERROR_RE.test(searchParams);

export const clearAllCookies = () => {
	const cookies = document.cookie.split(';');
	for (const cookie of cookies) {
		const [name] = cookie.split('=');
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
	}
};

export const cleaClinetBrowserCache = () => {
	localStorage.clear();
	sessionStorage.clear();
	clearAllCookies();
};
