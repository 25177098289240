import * as React from 'react';
import { FunctionComponent } from 'react';
import { Box, Button, Heading, Icon, Inline, Section, Stack, Text, useTextStyles } from '@autoguru/overdrive';
import { GraphError } from '@autoguru/relay/GraphError';
import { AlertIcon } from '@autoguru/icons';
import { CopyToClipboard } from '@autoguru/clipboard';
import { ContainedLayout } from '@autoguru/layout';

interface Props {
	error: GraphError;
	hideDetails?: boolean;
	resetErrorBoundary: (...args: any[]) => void;
}

export const ErrorFallback: FunctionComponent<Props> = ({
															error,
															resetErrorBoundary,
															hideDetails,
														}) => (
	<ContainedLayout paddingX='1' width='narrow'>
		<Section paddingX='1' width='small'>
			<Box
				aria-disabled='true'
				borderWidth='1'
				boxShadow='1'
				borderColour='light'
				paddingY={['2', '3']}
				paddingX={['2', '3']}
				borderRadius='1'
			>
				<Stack width='full' space='2' alignItems='center'>
					<Inline space='5'>
						<Icon
							className={useTextStyles({ colour: 'danger' })}
							size='large'
							icon={AlertIcon}
						/>
						{hideDetails && (
							<Heading is='h4'>Something Went Wrong</Heading>
						)}
					</Inline>
					<Stack space='5' width='full' alignItems='stretch'>
						<Box width='full' padding='2' backgroundColour='gray100' borderRadius='1'>
							{hideDetails ? (
								<Text is='p' strong size='3' colour='danger'>
									An error has occurred
								</Text>
							) : (
								<Stack space='2' width='full' alignItems='stretch'>
									<Text is='p' strong size='3' colour='danger'>{error.message}</Text>
									{Array.isArray(error.graphqlErrors) && error.graphqlErrors.map((error, index) => (
										<Text is='p' key={index} size='2' colour='warning'>• {error.message}</Text>
									))}
								</Stack>
							)}

						</Box>
						<Inline space='3' width='full' alignX='flexEnd'>
							<Button minimal onClick={resetErrorBoundary} size='small' variant='secondary'>
								Try again
							</Button>
							<CopyToClipboard variant='secondary'
											 size='small'
											 minimal
											 title='Copy'
											 toolTip='Copy error details'
											 stringProvider={() => JSON.stringify({
												 message: error.message,
												 graphqlErrors: error.graphqlErrors,
											 })} />
						</Inline>
					</Stack>
				</Stack>
			</Box>
	</Section>
	</ContainedLayout>
);
