import {
	AuthProvider,
	createClient,
	withRequiredAuthentication,
} from '@autoguru/auth';
import { getToken } from '@autoguru/auth/relay';
import { ContactNumbersProvider, ScrollToTop } from '@autoguru/components';
import { AsyncBoundary } from '@autoguru/suspense';
import { PageLoading } from '@autoguru/components/PageLoader';
import { OverdriveProvider } from '@autoguru/overdrive';
import { themeRef } from '@autoguru/overdrive/dist/themes/base/vars.css';
import { themeContractVars as vars } from '@autoguru/overdrive/dist/themes/theme.css';

import { createEnvironment, EnvironmentProvider } from '@autoguru/relay';
import * as React from 'react';
import { lazy } from 'react';

import { DEPortalHome } from '../pages';

const UserMenu = lazy(
	async () => import('@autoguru/components/components/UserMenu/UserMenu'),
);
const authClient = createClient();
const relayModernEnvironment = createEnvironment({
	fetcherOptions: { getToken },
});

const AuthRequiredApp = withRequiredAuthentication(
	() => (
		<EnvironmentProvider environment={relayModernEnvironment}>
			<AsyncBoundary fallback={PageLoading}>
				<UserMenu />
				<DEPortalHome />
			</AsyncBoundary>
		</EnvironmentProvider>
	),
	true,
);

export const App = () => (
	<OverdriveProvider
		noBodyLevelTheming={false}
		themeClass={themeRef}
		vars={vars}
	>
		<AuthProvider allow_socials optionalAuthentication client={authClient}>
			<ContactNumbersProvider>
				<ScrollToTop />
				<AsyncBoundary fallback={PageLoading}>
					<AuthRequiredApp />
				</AsyncBoundary>
			</ContactNumbersProvider>
		</AuthProvider>
	</OverdriveProvider>
);
