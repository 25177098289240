import * as React from 'react';
import { ComponentProps, FunctionComponent, ReactNode } from 'react';
import { Box } from '@autoguru/overdrive';

import * as styles from './ContainedLayout.css';
import { invariant } from '@autoguru/utilities';

interface Props extends Partial<Omit<ComponentProps<typeof Box>, 'width'>> {
	className?: string;
	children: ReactNode | ReactNode[];
	narrowYMargins?: boolean;
	width?: 'narrow' | 'medium' | 'wide' | 'extra-wide' | 'bleed';
	dropMargins?: boolean;
	dropYMargins?: boolean;
	dropMobileXMargins?: boolean;
}

export const ContainedLayout: FunctionComponent<Props> = ({
	children,
	className = '',
	width = 'medium',
	dropMargins = false,
	narrowYMargins = false,
	dropYMargins = false,
	dropMobileXMargins = false,
	is = 'section',
	...boxProps
}) => {
	invariant(
		!(dropYMargins && narrowYMargins),
		'Both dropYMargins & narrowYMargins cannot be true at the same time',
	);

	return (
		<Box
			{...boxProps}
			is={is}
			width="full"
			className={[
				styles.root,
				className,
				{
					[styles.narrow]: width === 'narrow',
					[styles.wide]: width === 'wide',
					[styles.extraWide]: width === 'extra-wide',
					[styles.bleed]: width === 'bleed',
				},
			]}
		>
			<Box
				width="full"
				className={[
					styles.contents,
					{
						[styles.withYMargins]: !dropYMargins && !dropMargins,
						[styles.withoutYMargins]: dropYMargins,
						[styles.withNarrowYMargins]: narrowYMargins,
						[styles.withMobileXMargins]:
							!dropMobileXMargins && !dropMargins,
						[styles.withoutMobileXMargins]: dropMobileXMargins,
						[styles.withoutMargins]: dropMargins,
					},
				]}
			>
				<Box width="full" className={styles.childHolder}>
					{children}
				</Box>
			</Box>
		</Box>
	);
};
