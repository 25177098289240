export const agentCanCopy = async (): Promise<boolean> => {
	try {
		const result = await navigator.permissions.query({
			// @ts-ignore
			name: 'clipboard-write',
		});

		return result.state == 'granted' || result.state == 'prompt';
	} catch (e) {
		// Firefox 63- does not support clipboard permissions
		return typeof navigator.clipboard?.writeText === 'function';
	}
};
