import * as React from 'react';
import { createContext, FunctionComponent, ReactNode, useContext } from 'react';
import { createLoggingAgent } from '../new-relic';
import { MicroAgent } from '@newrelic/browser-agent/loaders/micro-agent';
import { getFeatureName, setFeatureName } from '@autoguru/feature-identification';

interface Props {
	children: ReactNode;

	agentIdentifier?: Parameters<typeof createLoggingAgent>[0]['agentIdentifier']; // Add options prop here
	options?: Parameters<typeof createLoggingAgent>[0]['options']; // Add options prop here
}


export const LoggingAgentContext = createContext<MicroAgent | null>(null);

export const LoggingAgentProvider: FunctionComponent<Props> = ({
																   children,
																   options,
																   agentIdentifier = getFeatureName(),
															   }) => {
	setFeatureName({ mfeAppName: __GDU_APP_NAME__ });
	const agent = createLoggingAgent({ options, agentIdentifier });

	return (
		<LoggingAgentContext.Provider value={agent}>
			{children}
		</LoggingAgentContext.Provider>
	);
};
const isBrowser = typeof window !== 'undefined';

export const useLoggingAgent = (): MicroAgent | null => {
	return isBrowser ? useContext(LoggingAgentContext) : null;
};
