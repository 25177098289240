import { getAccessToken, hasCurrentClient, isAuthenticated } from '.';

/**
 * Serves as a convince method used to integrate with our Relay createEnvironment function.
 * @returns a JWT Bearer token only if the user is currently logged in.
 */
export const getToken = async (): Promise<string | null> => {
	return hasCurrentClient() && (await isAuthenticated())
		? getAccessToken()
		: null;
};
