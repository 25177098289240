import { createLogger } from '@autoguru/utilities';

import { GraphError } from './GraphError';
import { logException } from '@autoguru/logs';
import { LogFunction } from 'relay-runtime';
import { getLoggingAgent } from '@autoguru/logs/new-relic/agent';

const loggerCategory = 'relay';
const logger = createLogger(loggerCategory);

export const relayLogFn: LogFunction = (event: any) => {
	if (event.name === 'network.error') {
		typeof window !== 'undefined' && logException(event.error, {}, getLoggingAgent());
		if (event.error instanceof GraphError) {
			const { graphqlErrors = [] } = event.error;

			if (__DEV__ && process.__browser__) {
				graphqlErrors.forEach((item) => {
					console.error(
						`${loggerCategory} ${event.name} (${event.transactionID})`,
						item,
					);
				});
			} else if (!process.__browser__) {
				logger.error(event.name, {
					transactionID: event.transactionID,
					message: event.error.message,
					stackTrace: event.error.stack,
					graphqlErrors,
				});
			}
		} else {
			logger.error(event.name, {
				transactionID: event.transactionID,
				message: event.error.message,
				stackTrace: event.error.stack,
			});
		}
	}
};
