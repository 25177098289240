import '@autoguru/overdrive/dist/reset/globalReset.css';
import '@autoguru/overdrive/dist/reset/globalFonts.css';
import { AsyncBoundary } from '@autoguru/suspense';
import * as React from 'react';
import { StrictMode } from 'react';
import { LoggingAgentProvider } from '@autoguru/logs';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { App } from './App';
import { getMfeMountPoint } from 'gdu/dist/lib/mfe';

import * as guruConfigs from '../guru.config';
import { routeMap } from '~/src/pages/routeMap';

const root = getMfeMountPoint({
	mountDOMId: guruConfigs.mountDOMId,
	projectName: __GDU_APP_NAME__,
});
const MFE = () => {
	createRoot(root).render(
		<StrictMode>
			<LoggingAgentProvider>
				<AsyncBoundary fallback={null}>
					<Router>
						<Routes>
							<Route
								path={`${routeMap.slug}`}
								element={<App />}
							/>
						</Routes>
					</Router>
				</AsyncBoundary>
			</LoggingAgentProvider>
		</StrictMode>,
	);
};

export default MFE;
