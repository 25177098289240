import { AG_FEATURE } from '../types';

export const featureWindowVariableName = '__FEATURE_NAME__';

export const getFeatureName = (): AG_FEATURE | 'na' => {
	if (typeof window !== 'undefined') {
		return window[featureWindowVariableName] as AG_FEATURE;
	}
	return 'na';
};

export const setFeatureName = ({ featureName, mfeAppName }: { featureName?: AG_FEATURE; mfeAppName?: string }) => {
	if (typeof window === 'undefined' || window[featureWindowVariableName] || (!featureName && !mfeAppName))
		return;
	window[featureWindowVariableName] = featureName || buildFeatureName(mfeAppName || '');

};

export const buildFeatureName = (mfeName: string) => {
  if (mfeName.startsWith('@autoguru/')) {
    mfeName = mfeName.slice(10); // Remove '@autoguru/' from the start
  }
  return mfeName.replace(/(-app-shell$)|-/g, (_, group1) => group1 ? '' : '_').toUpperCase();
}
