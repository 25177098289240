import { RefObject, useCallback, useEffect, useState } from 'react';
import { agentCanCopy, setTimeoutWithCallBack } from '../lib';

interface Props {
	triggerRef?: RefObject<HTMLElement>;
	successMessage?: string;

	stringProvider(): string | Promise<string>;

	onFailedCopy?(): void;

	onCopied?(): void;
}

type Returns = [() => void, string | null];

export const useClipboardCopy = ({
	successMessage = 'Copied!',
	triggerRef,
	stringProvider,
	onFailedCopy,
	onCopied,
}: Props): Returns => {
	const [tip, setTip] = useState<string | null>();

	const onFailed = useCallback(() => {
		setTip('Failed to get copy permission from user machine!');
		if (typeof onFailedCopy === 'function') onFailedCopy();
	}, [onFailedCopy]);

	const onCopyRequest = useCallback(() => {
		(async () => {
			if (!(await agentCanCopy())) return onFailed();
			const clip = stringProvider();
			navigator.clipboard
				.writeText(await stringProvider())
				.catch(() => {
					onFailed();
					setTip('Failed to get copy permission from user machine!');
					console.warn(
						`Failed to copy the following into clipboard: ${clip}`,
					);
				})
				.then(() => {
					typeof onCopied === 'function' && onCopied();
					setTip(successMessage);
				});
		})();
	}, [stringProvider, onFailedCopy, onCopied]);

	useEffect(() => {
		let clearCallback;
		if (tip)
			clearCallback = setTimeoutWithCallBack(() => setTip(null), 3e3);

		return clearCallback ?? void 0;
	}, [tip]);

	useEffect(() => {
		if (!triggerRef?.current) return void 0;
		triggerRef.current.addEventListener('click', onCopyRequest, {
			passive: true,
		});
		return () => {
			triggerRef.current?.removeEventListener('click', onCopyRequest);
		};
	}, [triggerRef?.current, onCopyRequest]);

	return [onCopyRequest, tip];
};
