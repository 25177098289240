import * as React from 'react';
import { FunctionComponent, ReactNode } from 'react';
import { Environment, IEnvironment, Network, RecordSource, Store } from 'relay-runtime';
import type { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import { relayLogFn } from './environment';
import { RelayEnvironmentProvider, useRelayEnvironment as useRelayEnvironmentImported } from 'react-relay/hooks';
import { fetchQuery } from './fetch';
import { subscribe } from './subscription';

interface FetchFactoryOptions {
	/**
	 * A method to _optionally_ return a JWT Bearer token to pass onto the network call. Return NULL to ignore.
	 */
	getToken?: () => Promise<string | null>;
	scheme?: string;
}

const STORE_ENTRIES = 1500;
const STORE_CACHE_RELEASE_TIME = 10 * 60 * 1000; // 10 mins

let withSubscription = false;
const agRelayClientWithSub = '__AG_RelayClientWithSub__';
const agRelayClient = '__AG_RelayClient__';
let persistEnv: IEnvironment | null = null;


const getMemoEnv = (withSub: boolean): IEnvironment | null => {
	if (!process.__browser__ || typeof window === 'undefined')
		return persistEnv;
	return withSub ? window[agRelayClientWithSub] : window[agRelayClient];
};

const setMemoEnv = (withSub: boolean, env: IEnvironment): void => {
	if (!process.__browser__ || typeof window === 'undefined')
		persistEnv = env;
	else {
		if (withSub)
			window[agRelayClientWithSub] = env;
		else
			window[agRelayClient] = env;
	}

};

/**
 * Creates a new [Relay Environment]{@link Environment}. This environment creation will memo in CSR contexts, to then
 * subsequently use {@link getEnvironment} to retrieve.
 *
 * @supported CSR, SSR
 */
export const createEnvironment = (options?: {
	records?: RecordMap;
	fetcherOptions?: FetchFactoryOptions;
}): IEnvironment => {
	withSubscription = false;
	if (getMemoEnv(withSubscription))
		return getMemoEnv(withSubscription);

	const source = new RecordSource(options?.records ?? {});
	const store = new Store(source, {
		gcReleaseBufferSize: STORE_ENTRIES,
		queryCacheExpirationTime: STORE_CACHE_RELEASE_TIME,
	});

	const network = Network.create(fetchQuery(options?.fetcherOptions ?? {}));

	const environment = new Environment({
		isServer: !process.__browser__,
		log: relayLogFn,
		treatMissingFieldsAsNull: true,
		configName: 'standard',
		network,
		store,
	});

	setMemoEnv(withSubscription, environment);

	return environment;
};
export const createEnvironmentWithSubscription = (options?: {
	records?: RecordMap;
	fetcherOptions?: FetchFactoryOptions;
}): IEnvironment => {
	withSubscription = true;
	if (getMemoEnv(withSubscription))
		return getMemoEnv(withSubscription);

	const source = new RecordSource(options?.records ?? {});
	const store = new Store(source, {
		gcReleaseBufferSize: STORE_ENTRIES,
		queryCacheExpirationTime: STORE_CACHE_RELEASE_TIME,
	});

	const network = Network.create(
		fetchQuery(options?.fetcherOptions ?? {}),
		subscribe,
	);

	const environment = new Environment({
		isServer: !process.__browser__,
		log: relayLogFn,
		treatMissingFieldsAsNull: true,
		configName: 'standard',
		network,
		store,
	});

	setMemoEnv(withSubscription, environment);
	return environment;
};


export const initPersistedEnvironment = (initialRecords?: any, withSub = false) => {
	// Create a network layer from the fetch function
	const environment = getMemoEnv(withSub) ?? createEnvironment(initialRecords);

	// If your page has Next.js data fetching methods that use Relay, the initial records
	// will get hydrated here
	if (initialRecords) {
		environment.getStore().publish(new RecordSource(initialRecords));
	}
	// For SSG and SSR always create a new Relay environment
	if (typeof window === 'undefined') return environment;
	// Create the Relay environment once in the client
	setMemoEnv(withSub, environment);

	return environment;
};

/**
 * Gets you the current RelayEnvironment, this may return null if we have yet to set one up.
 *
 * Please note; in SSR this will always return null, please use {@link createEnvironment} instead.
 *
 * @supported CSR
 */
export const getEnvironment = () => getMemoEnv(withSubscription);

export const EnvironmentProvider: FunctionComponent<{
	environment: IEnvironment;
	children: ReactNode;
}> = ({ children, environment }) => (
	<RelayEnvironmentProvider environment={environment}>
		{children}
	</RelayEnvironmentProvider>
);

// TODO: Remove this any at some point?
export const useRelayEnvironment = (): any => useRelayEnvironmentImported();
