import { GraphError } from '@autoguru/relay/GraphError';
import { MicroAgent } from '@newrelic/browser-agent/loaders/micro-agent';
import { buildLogContext } from '../lib';

export const logException = (error: GraphError|Error, contexts: any = {}, agent: MicroAgent | null) => {
	if (__DEV__)
		console.error({ error });
	const customAttributes = {
		graphqlErrors: error instanceof GraphError?error.graphqlErrors: []
			.map((i) => `${i.message}@${i.path?.join('.') ?? 'na'}`)
			.join('\n'),
		errorMessage: error.message,
		...buildLogContext(contexts),
	};
	if (agent) {
		agent.noticeError(error, customAttributes);
	}
};
