import type { MicroAgent } from '@newrelic/browser-agent/loaders/micro-agent';
import { buildLogContext } from '../lib';


export const augmentAgent = (agent: MicroAgent):MicroAgent => {
	const loggingContext = buildLogContext();
	Object.keys(loggingContext).forEach((key) => {
		agent.setCustomAttribute(key, loggingContext[key] || 'NA');
	});
	return agent;
}
