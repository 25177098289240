import "../../node_modules/@autoguru/overdrive/dist/themes/theme.css.js.vanilla.css!=!../../../../node_modules/gdu/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../node_modules/@autoguru/overdrive/dist/themes/theme.css.js.vanilla.css\",\"source\":\"Ll8xeHdyN295NTEgewogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7CiAgZm9udDogNDAwIDE2cHgvMjJweCBBdmVydGFTdGFuZGFyZCwgc3lzdGVtLXVpLCBzYW5zLXNlcmlmOwp9\"}!../../../../node_modules/gdu/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../packages/layout/components/ContainedLayout/ContainedLayout.css.ts.vanilla.css!=!../../../../node_modules/gdu/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../packages/layout/components/ContainedLayout/ContainedLayout.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WU3W7CIBTH730K4pVNVldat1a82Zssp5QqGx8N4qxZfPdFbFrojM3cDQmc3/98cWC5E/hjVSfoe4aQBLPliiA4WL1x+zY+8sruCCrSpGk3s/NseRXgKQHGSahIO8VAZCMi+0UUI2I1JrJ0RLw4ouL7RsCJoK3hlW8lJau1YQ6iWlmmLEHz+cbXlELTz8sJFQwMQaW2u8AH1JaZ/7h4deJLbjHV4iAVQelz5gH5AFgmGwGWdeSeIFwbH71ZktKKBRldDzzVjSKmRMW9rCRXEtrFF5hFHL/j9mhyfVpHT242IrdOMF6kdTBdSTdfvRlCc5BzLZg/D/BQe+CR9pRBVmGRRTSugd6h84F+k6zigPbUMKYQqAotJFf9A0kS3LSRcxU8gL9dE/TXhGszgVw6cB7ClV2424XQqP8aPA29q1n7mvMP699zzKAEAAA=\"}!../../../../node_modules/gdu/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bleed = 'hl1j4f4';
export var childHolder = 'hl1j4f6';
export var contents = 'hl1j4f5';
export var extraWide = 'hl1j4f3';
export var narrow = 'hl1j4f0';
export var root = 'hl1j4f1';
export var wide = 'hl1j4f2';
export var withMobileXMargins = 'hl1j4f8';
export var withNarrowYMargins = 'hl1j4fc';
export var withYMargins = 'hl1j4fb';
export var withoutMargins = 'hl1j4fa';
export var withoutMobileXMargins = 'hl1j4f7';
export var withoutYMargins = 'hl1j4f9';