import { useAuth } from '@autoguru/auth';
import { PageLoading } from '@autoguru/components';
import { AsyncBoundary } from '@autoguru/suspense';
import { ContainedLayout } from '@autoguru/layout';
import * as React from 'react';
import { FunctionComponent, lazy, useEffect } from 'react';
import { loggedInDashboardUrl } from '~/src/pages';
import { generatePath, useParams } from 'react-router-dom';

const UnAuthedUserScreen = lazy(
	async () =>
		import('../../features/unauthed-in-user-screen/UnAuthedUserScreen'),
);
const Landing: FunctionComponent = () => {
	const { slug } = useParams();
	const { isAuthenticated } = useAuth();
	useEffect(() => {
		if (isAuthenticated)
			window.location.replace(
				generatePath(loggedInDashboardUrl, { slug: slug }).replace(
					'https:/mygarage',
					'https://mygarage',
				),
			);
	}, [isAuthenticated]);
	return (
		<ContainedLayout>
			<AsyncBoundary fallback={PageLoading}>
				{isAuthenticated ? null : <UnAuthedUserScreen />}
			</AsyncBoundary>
		</ContainedLayout>
	);
};

export default Landing;
