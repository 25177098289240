import { getCurrentClient } from './client';
import { AUTH_REDIRECT_APP } from '@autoguru/global-configs';
import { AuthOptions } from '../react';
import { TENANT_NAME, REGION } from '@autoguru/global-configs';

export const login = (returnTo: string, options: Partial<AuthOptions> = {}) => {
	const {
		allow_socials = true,
		initialScreen = 'signUp',
		logoUrl,
		termsAndConditionsUrl,
		privacyPolicyUrl,
	} = {
		allow_socials: true,
		initialScreen: 'login',
		...options,
	};

	getCurrentClient().loginWithRedirect({
		authorizationParams: {
			disallow_social: allow_socials ? void 0 : !allow_socials,
			initial_screen: initialScreen,
			redirect_uri: `${AUTH_REDIRECT_APP}?ag-target=${returnTo}`,
			logoUrl,
			termsAndConditionsUrl,
			privacyPolicyUrl,
			autoguru_tenant: TENANT_NAME,
			autoguru_region: REGION,
		},
	});
};
export const signup = (
	returnTo: string,
	options: Partial<AuthOptions> = {},
) => {
	const {
		allow_socials = true,
		initialScreen = 'signUp',
		token,
		logoUrl,
		termsAndConditionsUrl,
		privacyPolicyUrl,
	} = {
		allow_socials: true,
		token: '',
		initialScreen: 'signUp',
		...options,
	};
	getCurrentClient().loginWithRedirect<{
		initial_screen: string;
		disallow_social: boolean;
		redirect_uri: string;
		lead_guid: string;
		logoUrl: string;
		termsAndConditionsUrl: string;
		privacyPolicyUrl: string;
		autoguru_tenant: string;
		autoguru_region: string;
	}>({
		authorizationParams: {
			lead_guid: token,
			redirect_uri: `${AUTH_REDIRECT_APP}?ag-target=${returnTo}`,
			initial_screen: initialScreen,
			disallow_social: allow_socials ? void 0 : !allow_socials,
			logoUrl,
			termsAndConditionsUrl,
			privacyPolicyUrl,
			autoguru_tenant: TENANT_NAME,
			autoguru_region: REGION,
		},
	});
};

export const logout = (returnTo: string, localOnly = false) =>
	void getCurrentClient().logout({
		logoutParams: {
			returnTo: `${AUTH_REDIRECT_APP}?ag-target=${returnTo}`,
			localOnly,
		},
	});
