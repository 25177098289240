import { invariant } from '@autoguru/utilities';
import * as React from 'react';
import {
	createContext,
	FunctionComponent,
	ReactNode,
	useContext,
	useMemo,
} from 'react';

interface ContactNumbersProviderValue {
	readonly successContactNumber;
	readonly ordersContactNumber;
	readonly agCustomerContactNumber;
	readonly wexContactNumber;
	readonly ampolContactNumber;
}

interface Props {
	children: ReactNode | ReactNode[];
}

const contactNumbersContext = createContext<ContactNumbersProviderValue | null>(
	null,
);

export const useContactNumbers = () => {
	const contextValue = useContext(contactNumbersContext);

	invariant(
		contextValue !== null,
		'Please have a  <ContactNumbersProvilder /> set in your tree',
	);

	return contextValue;
};

export const ContactNumbersProvider: FunctionComponent<Props> = ({
	children,
}) => {
	const contactNumbersContextValue = useMemo<ContactNumbersProviderValue>(
		() => ({
			successContactNumber: '07 5612 5347',
			ordersContactNumber: '02 5300 2825',
			agCustomerContactNumber: '1300 655 661',
			wexContactNumber: '1300 319 886',
			ampolContactNumber: '1300 656 014',
		}),
		[],
	);

	return (
		<contactNumbersContext.Provider value={contactNumbersContextValue}>
			{children}
		</contactNumbersContext.Provider>
	);
};
